import { HighlightOffOutlined, Visibility } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../application/stores/AppContext';
import { useInnovationContext } from '../application/stores/InnovationContext';
import CustomCard from '../components/CustomCard';
import LinearWithValueLabel from '../components/widgets/ProgressBar';
import logo from './../assets/images/logo_without_name.png';
import { Link } from 'react-router-dom';

const InnovationLabPage = () => {
  const { windowWidth, setHeaderImage, setLabel } = useAppContext();
  const {
    createdCardsList,
    deleteCreatedCard,
    progress,
    setProgress,
    loading,
    setLoading,
  } = useInnovationContext();

  const [x, setX] = useState(4);

  useEffect(() => {
    if (progress == 100) {
      setProgress(0);
      setLoading(false);
    }
  }, [progress]);

  useEffect(() => {
    if (windowWidth >= 1949) {
      setX(5);
    }
    if (windowWidth >= 1570) {
      setX(4);
    }
    if (windowWidth < 1570) {
      setX(3);
    }
    if (windowWidth < 1150) {
      setX(2);
    }
    if (windowWidth < 800) {
      setX(1);
    }
  }, [windowWidth]);

  useEffect(() => {
    setHeaderImage(logo);
    setLabel('');
  }, []);

  if (!createdCardsList || createdCardsList.length < 1) {
    return (
      <div
        className={`bg-background ${
          windowWidth > 500 ? 'px-4' : ''
        } min-h-[83vh] flex  max-h-[83vh] max-w-full justify-center items-center w-full flex-col`}
      >
        <div
          className="flex flex-col w-fit h-[20vh] justify-center items-center bg-white rounded-xl p-4  md:p-12 ${
          windowWidth > 660 ? 'py-8' : 'pt-8'
        "
        >
          <span className="text-lg">Please be patient</span>
          <br />
          <span className="text-lg">
            This page is under research and will be soon available...
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`bg-background ${
          windowWidth > 500 ? 'px-4' : ''
        } min-h-[83vh] relative max-h-[83vh] max-w-full w-full flex-col`}
      >
        <div
          className={`w-full h-full bg-white rounded-lg p-4  md:p-8 ${
            windowWidth > 660 ? 'py-8' : 'pt-8'
          }`}
        >
          {createdCardsList && (
            <div
              className={`grid bg-white ${
                x === 5
                  ? createdCardsList.length < x
                    ? 'grid-cols-4'
                    : 'grid-cols-5'
                  : x === 4
                    ? createdCardsList.length < x
                      ? 'grid-cols-3'
                      : 'grid-cols-4'
                    : x === 3
                      ? 'grid-cols-3'
                      : x === 2
                        ? 'grid-cols-2'
                        : 'grid-cols-1'
              } gap-16 items-start pl-[14px] min-h-[83vh] max-h-[83vh] w-full max-w-full pb-12 max-md:pb-36 md:pr-7 overflow-y-scroll`}
            >
              {/* sort available assistants and add them to display */}
              {createdCardsList.map((bot) => (
                <div
                  key={bot.actionAreaKey}
                  className="p-2 flex items-center justify-center relative"
                >
                  <CustomCard cardProps={bot} />
                  <div
                    className={
                      progress < 3 || progress == 100
                        ? 'w-full h-full items-center hover:backdrop-blur-sm opacity-0 hover:opacity-100 justify-evenly flex-col flex absolute hover:z-50'
                        : 'w-full h-full items-center backdrop-blur-sm opacity-100 justify-between flex absolute hover:z-50'
                    }
                  >
                    <Link
                      to={'/lab/' + bot.redirect_link.split('/market/')[1]}
                      className={
                        progress < 3 || progress == 100
                          ? 'p-2 rounded-lg min-w-full max-w-48 backdrop-blur-none cursor-pointer bg-background bg-opacity-35 hover:bg-opacity-100 grid grid-cols-[40%_60%] pl-16 -gap-16 items-center justify-center text-[1.375rem] font-semibold pr-4 '
                          : 'hidden'
                      }
                    >
                      <Visibility
                        size="100px"
                        sx={{ width: 70, height: 70, color: '#3a48ff' }}
                      />
                      <div className="pl-3">Preview</div>
                    </Link>
                    {/* <div
                      onClick={async () => {}}
                      className={
                        progress < 3 || progress == 100
                          ? 'p-2 rounded-lg min-w-full min-h-20 max-w-48 backdrop-blur-none cursor-pointer bg-background bg-opacity-35 hover:bg-opacity-100 grid grid-cols-[40%_60%] pl-16 -gap-16 items-center justify-center text-[1.375rem] font-semibold pr-4 '
                          : 'hidden'
                      }
                    >
                      <Edit
                        size="100px"
                        sx={{ width: 70, height: 60, color: '#5760ce' }}
                      />
                      <div className="pl-3">Edit</div>
                    </div> */}
                    <div
                      onClick={async () => {
                        deleteCreatedCard(bot);
                        setLoading(true);
                      }}
                      className={
                        progress < 3 || progress == 100
                          ? 'p-2 rounded-lg min-w-full max-w-48 backdrop-blur-none cursor-pointer bg-background bg-opacity-35 hover:bg-opacity-100 grid grid-cols-[40%_60%] pl-16 -gap-16 items-center justify-center text-[1.375rem] font-semibold pr-4 '
                          : 'hidden'
                      }
                    >
                      <HighlightOffOutlined
                        size="100px"
                        sx={{ width: 70, height: 70, color: '#88001B' }}
                      />
                      <div className="pl-3">Delete</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {loading && (
          <div className="flex absolute top-0  left-[30%] w-[40%] h-full justify-center items-center">
            <div className="h-28 w-[100%] z-50 pt-1 px-16 rounded-2xl bg-background flex items-center border-2 border-border border-opacity-20">
              <LinearWithValueLabel
                progress={progress}
                setProgress={setProgress}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default InnovationLabPage;
