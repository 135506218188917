import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import '../App.css';
import CustomBaseModal from '../components/CustomBaseModal';
import Header from '../components/Header';
import SideBar from '../components/Sidebar';
import ChatBot from '../pages/ChatBot';
import InnovationLabPage from '../pages/InnovationLabPage';
import Favourite from '../pages/FavouritePage';
import Info from '../pages/Info';
import Login from '../pages/Login';
import Profile from '../pages/Profile';
import Register from '../pages/Register';
import Store from '../pages/Store';
import ProtectedRoute from './ProtectedRoute';
import CollectionPage from '../pages/CollectionPage';
import Preview from '../pages/Preview';

const ProtectedRoutesWrapper = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;

    // Redirect '/' to '/search'
    if (currentPath === '/') {
      navigate('/market');
    }

    if (currentPath === '/register') {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="w-full max-w-full h-screen max-h-screen overflow-y-clip ">
      <Header />
      <div className="w-full flex flex-row justify-start items-start max-w-screen h-max">
        <SideBar />
        <div className="w-full min-h-[84vh] h-[84vh]">
          <>
            <Routes>
              {/* <Route
                          path="/"
                          element={<ProtectedRoute component={Dashboard} />}
                      /> */}
              <Route
                path="/market"
                element={<ProtectedRoute component={Store} />}
              />
              <Route
                path="/collection"
                element={<ProtectedRoute component={CollectionPage} />}
              />
              <Route
                path="/market/:name"
                element={<ProtectedRoute component={ChatBot} />}
              />
              <Route path="/favorite" element={<Favourite />} />
              <Route
                path="/info"
                element={<ProtectedRoute component={Info} />}
              />
              <Route
                path="/lab"
                element={<ProtectedRoute component={InnovationLabPage} />}
              />
              <Route
                path="/lab/:name"
                element={<ProtectedRoute component={Preview} />}
              />
              <Route
                path="/profile"
                element={<ProtectedRoute component={Profile} />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Routes>
          </>
        </div>
      </div>
      <CustomBaseModal />
    </div>
  );
};

export default ProtectedRoutesWrapper;
