import React, { createContext, useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { generateAlphanumericString } from '../protocols/guard/guard';
import { HasuraServices } from '../protocols/services/api_services';
import { useMarketContext } from './MarketContext';
import { useProfileContext } from './ProfileContext';
import { useAppContext } from './AppContext';

const InnovationContext = createContext();

export const InnovationProvider = ({ children }) => {
  const { userId } = useProfileContext();
  const { base_url } = useAppContext();
  const [createdCardsList, setCreatedCardsList] = useState([]);
  const { forceFetchBots } = useMarketContext();
  const [progress, setProgress] = useState(2);
  const [loading, setLoading] = useState(false);

  const getCreatedCardList = () => {
    const getCreatedCardsQuery = `query GetCardInformation($user_id:uuid!){
      cards(where:{user_id:{_eq:$user_id}}){
          card_id
          category
          frontend_config{
          config_main
          }
          card_creation_process{
            title
            short_description
            detailed_description
            input_template
            input_template_example
            output_template
            output_template_example
            card_file{
              url_name
            }
          }
      }
  }`;

    const getCreatedCardsVariables = {
      user_id: userId,
    };

    HasuraServices.instance
      .query(getCreatedCardsQuery, getCreatedCardsVariables)
      .then((res) => {
        // console.log(
        //   res.msg?.cards
        //     ?.map((card) => card.card_creation_process)
        //     .filter((card_creation_process) =>
        //       card_creation_process ? true : false
        //     )
        // );
        const actual_list = res.msg?.cards;
        // const list = res.msg?.cards?.map(
        //   (card) => card.frontend_config[0].config_main
        // );
        setCreatedCardsList(
          actual_list?.map((cardData) => ({
            ...cardData.frontend_config[0].config_main,
            purpose_category: cardData?.category,
            inputTemplate: cardData?.card_creation_process?.input_template,
            inputTemplateExample:
              cardData?.card_creation_process?.input_template_example,
            outputTemplate: cardData?.card_creation_process?.output_template,
            outputTemplateExample:
              cardData?.card_creation_process?.output_template_example,
            image_addr: `${base_url}card-image${cardData.frontend_config[0].config_main.image_addr}`,
            actionAreaKey: generateAlphanumericString(4),
            id: cardData.card_id,
            card_files: cardData?.card_creation_process?.card_file?.map(
              (card_file_link) => ({
                image: card_file_link?.url_name,
                id: generateAlphanumericString(5),
              })
            ),
          }))
        );
      });
  };

  const deleteCreatedCard = (bot) => {
    const query = `mutation MyMutation($card_name:String!){
      delete_card(args:{card_name:$card_name}){
        message
        status
      }
    }`;
    const variables = {
      card_name: bot.id,
    };
    HasuraServices.instance.query(query, variables).then(async (res) => {
      if (
        res.msg.delete_card.status == '200' &&
        res.msg.delete_card.message == 'Card deleted successfully'
      ) {
        setTimeout(async () => {
          setLoading(false);
          setProgress(2);
          await Swal.fire({
            icon: 'success',
            text: 'Card deleted successfully.',
            confirmButtonColor: '#521E95',
            footer:
              'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
          });

          await forceFetchBots();
          setProgress(0);
          getCreatedCardList();
        }, 3000);
      } else {
        setLoading(false);
        setProgress(0);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Couldn't delete your card. Please try again after sometime.`,
          footer:
            'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
        });
      }
    });
  };

  useEffect(() => {
    if (userId) {
      getCreatedCardList();
    }
  }, [userId]);

  return (
    <InnovationContext.Provider
      value={{
        createdCardsList,
        deleteCreatedCard,
        progress,
        setProgress,
        loading,
        setLoading,
        getCreatedCardList,
      }}
    >
      {children}
    </InnovationContext.Provider>
  );
};

export const useInnovationContext = () => {
  return useContext(InnovationContext);
};
