import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardActionArea } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ShareIcon from "@mui/icons-material/Share";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const getCardBorderColor = (cardCategory) => {
  let color;
  switch (cardCategory) {
    case 'elite':
      color = 'blue';
      break;
    case 'ultimate':
      color = '#521E95';
      break;
    default:
      color = 'white';
  }
  return color;
};

/**
 * Returns color code that represents the purpose category of a card.
 *
 * @param {string} cardCategory - The purpose category of card.
 * @returns {string} - The hex code of color for the card purpose category.
 */
const getCardCategoryColor = (cardCategory) => {
  let color;
  switch (cardCategory) {
    default:
      color = '#521E95';
  }
  return color;
};

function LinkifyText(props) {
  const text = props.text;

  const regex = /(https?:\/\/[^ ]+)/g;
  const parts = text.split(regex);

  return (
    <div key={props.key}>
      {parts.map((part, index) => {
        if (index % 2 === 0) {
          return part;
        } else {
          return (
            <b>
              <a href={part} target="_blank" rel="noopener noreferrer">
                {part}
              </a>
            </b>
          );
        }
      })}
    </div>
  );
}

const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CustomCard({ cardProps, customKey }) {
  const [expanded, setExpanded] = useState(false);
  const [splittedTitle, setSplittedTitle] = useState();
  const imageAddress = cardProps.image_addr;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    const title = String(cardProps.display_name);

    const tempSplittedTitle = [];
    title.split('\n').forEach((value) => {
      tempSplittedTitle.push(value);
    });
    setSplittedTitle(tempSplittedTitle);
  }, [cardProps.display_name]);
  return (
    <Card
      key={customKey}
      sx={{
        maxWidth: 350,
        minWidth: 260,
        minHeight: 444,
        maxHeight: 444,
        borderTop: 12,
        borderColor: getCardBorderColor(cardProps.category),
      }}
      raised={true}
      // onFocus={}
      style={{
        borderRadius: '20px',
      }}
      className="mx-0 cursor-pointer  w-full min-w-[40px] relative"
    >
      <div className=" min-h-[416px]  flex h-full justify-between flex-col">
        {expanded ? (
          <Collapse
            in={expanded}
            timeout="auto"
            // unmountOnExit
            sx={{ display: 'flex', height: '100%' }}
          >
            <CardContent
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              {cardProps.detailed_description.split('\n').map((par, index) => {
                return <LinkifyText key={index} text={par} />;
              })}
            </CardContent>
          </Collapse>
        ) : (
          <Link
            to={cardProps.redirect_link}
            className="appearance-none text-black"
            style={{ textDecorationLine: 'none' }}
          >
            <CardContent
              sx={{
                paddingRight: 4,
                borderColor: getCardCategoryColor(cardProps.purpose_category),
                borderWidth: '1px',
                borderRadius: '8px',
                maxHeight: 72,
                minHeight: 72,
              }}
            >
              <p className="text-lg -mt-2 font-semibold text-black">
                {cardProps.purpose_category
                  .split(' ')
                  .map((value, index) =>
                    index == 0
                      ? value.charAt(0).toUpperCase() + value.slice(1)
                      : value
                  )
                  .join(' ')}
              </p>
            </CardContent>
            <CardActionArea>
              <div className="mt-2 flex flex-col justify-items-start py-3 min-h-[92px] max-h-[104px] ">
                {splittedTitle &&
                  splittedTitle.map((name, index) => (
                    <p
                      key={cardProps.actionAreaKey + index}
                      className="px-4 text-black text-lg"
                    >
                      {name}
                    </p>
                  ))}
              </div>
              <img
                src={imageAddress}
                loading="lazy"
                className="w-full max-h-56 min-w-max object-contain"
              />
            </CardActionArea>
          </Link>
        )}{' '}
        <CardActions disableSpacing className="flex w-full bottom-0 absolute">
          {/* It will be useful when we add functinality for adding LLMs as favourite */}
          {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton> */}

          {/* I will be useful when we add functionality for sharing LLMs to other users*/}
          {/* <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      </div>
    </Card>
  );
}
