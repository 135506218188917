// import { Edit } from '@mui/icons-material';
import { FormGroup } from '@mui/material';
import CustomInput from '../components/CustomInput';
// import { useRef, useState } from 'react';
// import logo from '../assets/images/logo_new_0_23_22_5_2024.png';
import { useParams } from 'react-router-dom';
import { useInnovationContext } from '../application/stores/InnovationContext';
import { useAppContext } from '../application/stores/AppContext';
import CustomSelectedImage from '../components/CustomSelectedImage';
import { useState } from 'react';
import { Button } from '@mui/material';
import { BiFile } from 'react-icons/bi';
import Section from '../components/Section';

const Preview = (props) => {
  const [longViewAdvanced, setLongViewAdvanced] = useState(false);
  const { setModal, modalHeight, setIsModalOpen } = useAppContext();
  const handleSelectedImageClick = (e) => {
    setIsModalOpen(true);
    setModal(
      <img
        src={e.target.src}
        style={{ maxHeight: modalHeight - 70 }}
        className="w-full max-w-full h-full max-h-full object-contain flex rounded-b-[26px]"
      />
    );
  };
  const [longViewGeneral, setLongViewGeneral] = useState(true);
  const { createdCardsList } = useInnovationContext();
  const { base_url } = useAppContext();

  // const fileInputRef = useRef(null);
  const routes = useParams();
  const name = routes.name;
  const card = createdCardsList.filter(
    (card) =>
      card.redirect_link.split('/')[card.redirect_link.split('/').length - 1] ==
      name
  )[0];

  // const navigate = useNavigate();

  return (
    <div
      className="px-4 md:px-5 xl:px-16 pt-0 pb-8 min-h-[100vh] overflow-y-clip w-full h-full max-w-full overflow-x-clip flex flex-col justify-center items-center"
      style={{ backgroundColor: 'whitesmoke' }}
    >
      <h1 className="text-4xl font-semibold">{props.title}</h1>

      <div className="w-full h-full flex flex-col mt-1 mr-0 overflow-y-scroll">
        <FormGroup
          sx={{
            width: '100%',
            maxWidth: '100%',
            gap: 5,
            bgcolor: 'white',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            borderRadius: '0.5rem',
            paddingTop: '40px',
            paddingBottom: '160px',
          }}
        >
          <Section longView={true} setLongView={() => {}}>
            <div className="flex max-h-72 -mb-8 justify-center items-center h-screen">
              <div className="relative">
                <img
                  priority={false}
                  width={200}
                  height={200}
                  src={
                    base_url +
                    'card-image/' +
                    card?.image_addr.split('/')[
                      card?.image_addr.split('/').length - 1
                    ]
                  }
                  alt="Selected"
                  className={`w-64 h-64 border-4 border-border border-opacity-10 object-cover rounded-lg`}
                />
              </div>

              <input
                id="file-input"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={() => {}}
              />
            </div>

            <div className="w-full flex justify-between">
              {card?.card_files && card?.card_files.length > 0 ? (
                <div className="flex w-full gap-5 items-center my-4">
                  <div className="flex h-full w-fit items-center justify-center">
                    <Button
                      endIcon={<BiFile />}
                      variant="contained"
                      type="button"
                      sx={{
                        borderRadius: 1,
                        bgcolor: '#ffffff00',
                        color: '#000',
                        maxHeight: 54,
                        cursor: 'default',
                        '&:hover': {
                          bgcolor: '#ffffff00',
                          color: '#000',
                        },
                      }}
                      className="hover:bg-[#ffffff00]"
                      onClick={() => {}}
                    >
                      Added Files
                    </Button>
                  </div>
                  <div className="mx-8 px-4 flex">
                    <div className="w-full flex flex-wrap gap-4 bg-transparent z-20">
                      {card?.card_files?.map((image) => (
                        <CustomSelectedImage
                          file={image.image}
                          images={card?.card_files}
                          imageWidth="60px"
                          imageHeight="60px"
                          paddingTop="4px"
                          closeBtnMarginRight="-16px"
                          closeBtnTop="-12px"
                          closeBtnSize={16}
                          closeBtnPadding="4px"
                          borderRadius="10px"
                          marginBottom="-36px"
                          showCloseButton={false}
                          index={image.id}
                          handleSelectedImageClick={handleSelectedImageClick}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* <div className="flex h-full w-fit items-center justify-center">
                <Button
                  endIcon={<Edit sx={{ color: '#5760ce' }} />}
                  variant="contained"
                  type="button"
                  sx={{
                    borderRadius: 1,
                    bgcolor: '#ffffff00',
                    color: '#000',
                    maxHeight: 54,
                    '&:hover': {
                      bgcolor: '#ffffff00',
                      color: '#000',
                    },
                  }}
                  className="hover:bg-[#ffffff00]"
                  onClick={() => {
                    navigate('/lab/' + name + '/edit');
                  }}
                >
                  Edit
                </Button>
              </div> */}
              {
                /* To be commented when edit page is complete*/
                <div></div>
              }
            </div>
          </Section>

          <Section
            longView={longViewGeneral}
            setLongView={setLongViewGeneral}
            label={'General'}
            disabled={false}
          >
            <CustomInput
              currentState={{ value: card?.display_name }}
              setState={() => {}}
              sx={{
                bgcolor: '#fff',
                border: 1,
                borderRadius: 1,
                paddingLeft: 4,
                borderColor: 'GrayText',
                paddingY: 1,
              }}
              label="Title"
              validator={() => {}}
            />
            <CustomInput
              label="Short Description"
              currentState={{ value: card?.short_description }}
              validator={() => true}
              setState={() => {}}
              multiLine={true}
              sx={{
                bgcolor: '#fff',
                border: 1,
                borderRadius: 1,
                paddingLeft: 4,
                borderColor: 'GrayText',
                paddingY: 2,
              }}
            />
            <CustomInput
              label="Detailed Description"
              currentState={{ value: card?.detailed_description }}
              multiLine={true}
              validator={() => true}
              setState={() => {}}
              sx={{
                bgcolor: '#fff',
                border: 1,
                borderRadius: 1,
                paddingLeft: 4,
                paddingRight: 2,
                borderColor: 'GrayText',
                paddingY: 2,
              }}
            />
          </Section>

          {(card?.outputTemplateExample ||
            card?.outputTemplate ||
            card?.inputTemplate ||
            card?.inputTemplateExample) && (
            <Section
              longView={longViewAdvanced}
              setLongView={setLongViewAdvanced}
              label="Advanced Configurations"
            >
              {card.inputTemplate && (
                <CustomInput
                  label="Input Template (Optional)"
                  currentState={{ value: card?.inputTemplate }}
                  multiLine={true}
                  setState={() => {}}
                  sx={{
                    bgcolor: '#fff',
                    border: 1,
                    borderRadius: 1,
                    paddingLeft: 4,
                    borderColor: 'GrayText',
                    paddingY: 2,
                  }}
                />
              )}
              {card.inputTemplateExample && (
                <CustomInput
                  label="Input Template Example (Optional)"
                  currentState={{ value: card?.inputTemplateExample }}
                  multiLine={true}
                  setState={() => {}}
                  sx={{
                    bgcolor: '#fff',
                    border: 1,
                    borderRadius: 1,
                    paddingLeft: 4,
                    borderColor: 'GrayText',
                    paddingY: 2,
                  }}
                />
              )}
              {card.outputTemplate && (
                <CustomInput
                  label="Output Template (Optional)"
                  currentState={{ value: card?.outputTemplate }}
                  multiLine={true}
                  setState={() => {}}
                  sx={{
                    bgcolor: '#fff',
                    border: 1,
                    borderRadius: 1,
                    paddingLeft: 4,
                    borderColor: 'GrayText',
                    paddingY: 2,
                  }}
                />
              )}
              {card.outputTemplateExample && (
                <CustomInput
                  label="Output Template Example (Optional)"
                  currentState={{ value: card?.outputTemplateExample }}
                  multiLine={true}
                  setState={() => {}}
                  sx={{
                    bgcolor: '#fff',
                    border: 1,
                    borderRadius: 1,
                    paddingLeft: 4,
                    borderColor: 'GrayText',
                    paddingY: 2,
                  }}
                />
              )}
            </Section>
          )}
        </FormGroup>
      </div>
    </div>
  );
};

export default Preview;
