import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { convertDateFormat } from '../../application/protocols/guard/guard';
import { HasuraServices } from '../../application/protocols/services/api_services';
import {
  LocalStorageService,
  LocalStorageServiceItems,
} from '../../application/storages/storage_services';
import { useProfileContext } from '../../application/stores/ProfileContext';
import cover_image from '../../assets/images/cover_image.jpg';
import CustomButton from '../CustomButton';
import CustomDropDown from '../CustomDropDown';
import CustomTextField from '../CustomTextField';
import PasswordField from '../PasswordField';
import RadioButton from '../RadioButton';
import CustomDateTimePicker from './CustomDateTimePicker/CustomDateTimePicker';
import { useMarketContext } from '../../application/stores/MarketContext';
import { useCollectionContext } from '../../application/stores/CollectionContext';
import { useInnovationContext } from '../../application/stores/InnovationContext';

//this is auth card
const AuthCard = () => {
  const navigate = useNavigate();

  const user_types = ['Individual', 'Organization'];

  const user_sources = [
    'Twitter',
    'Instagram',
    'LinkedIn',
    'Pinterest',
    'YouTube',
    'Snapchat',
    'TikTok',
    'WhatsApp',
    'Telegram',
    'Reddit',
    'GitHub',
    'Medium',
    'Other',
  ];
  const { getProfile } = useProfileContext();
  const { fetchBots } = useMarketContext();
  const { fetchBots: fetchCollectionBots } = useCollectionContext();
  const { getCreatedCardList: fetchLabBots } = useInnovationContext();

  const location = useLocation();
  const [dobDate, setDobDate] = useState(new Date());

  const isRegister = location.pathname === '/register';

  const [userSource, setuserSource] = useState('Facebook');

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    dob: '',
    password: '',
    company_name: '',
    user_type: '',
    user_source: '',
    confirm_password: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(() => ({
      ...formData,
      [name]: value,
      company_name: '',
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var doc = '';
    if (isRegister) {
      doc = `mutation Mymutation {
        signup(args: {dob: "${convertDateFormat(dobDate)}", company_name: "${
          formData.company_name
        }", email: "${formData.email}", first_name: "${
          formData.first_name
        }", last_name: "${formData.last_name}", password: "${
          formData.password
        }", user_source: "${userSource}", user_type: "${formData.user_type.toLowerCase()}"}) {
          access
          message
        }
      }`;
    } else {
      doc = `mutation login {
        login(args:{email: "${formData.email}",  password:"${formData.password}" }) {
          access
          message
        }
      }`;
    }
    const res = await HasuraServices.instance.query(doc, {});
    try {
      if (res.status && isRegister && res.msg.signup.access) {
        // navigate('/verify-otp')
        LocalStorageService.instance.set(
          LocalStorageServiceItems.ACCESS_TOKEN,
          res.msg.signup.access
        );
        getProfile().then(() => {
          fetchBots();
          fetchCollectionBots();
          fetchLabBots();
        });

        navigate('/');
      } else if (res.status && res.msg.login.access && !isRegister) {
        LocalStorageService.instance.set(
          LocalStorageServiceItems.ACCESS_TOKEN,
          res.msg.login.access
        );
        getProfile().then(() => {
          fetchBots();
          fetchCollectionBots();
          fetchLabBots();
        });

        navigate('/');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Couldn't ${
            isRegister ? 'register' : 'login'
          }. Please try again after sometime.`,
          footer:
            'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
        });
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Couldn't login. Please try again after sometime.",
        footer:
          'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mx-auto p-4 h-md">
      <img src={cover_image} alt="logo.png" className=""></img>
      <h1 className="text-font font-times text-xl font-semibold text-center mt-2">
        {' '}
        {isRegister ? 'Sign Up' : 'Login'}
      </h1>
      {/* <h1 className='text-font font-times text-sm font-semibold text-center'> {isRegister ? 'Act Intelligently' : 'Act Intelligently'}</h1> */}
      <div className="mt-2 p-4 w-full">
        {isRegister && (
          <div className="flex flex-col">
            <CustomTextField
              name="first_name"
              hintText={'First Name'}
              label={'First Name'}
              onChange={handleInputChange}
            />
            <CustomTextField
              name="last_name"
              hintText={'Last Name'}
              label={'Last Name'}
              onChange={handleInputChange}
            />
            <CustomDateTimePicker
              label="Date of Birth"
              value={dobDate}
              onChange={(ev) => setDobDate(ev)}
            />
          </div>
        )}
        <CustomTextField
          name="email"
          hintText={'Enter your email'}
          label={'Email'}
          onChange={handleInputChange}
        />
        <PasswordField
          name="password"
          hintText={'Enter your password'}
          label={'Password'}
          onChange={handleInputChange}
        />
        {isRegister && (
          <div className="flex flex-col  w-full mb-12">
            <p className="text-xl font-times text-font">
              Are you an Individual/Company ?{' '}
            </p>
            {user_types.map((e) => {
              return (
                <RadioButton
                  key={e}
                  value={e}
                  label={e}
                  name="user_type"
                  onChange={handleInputChange}
                />
              );
            })}
            {formData.user_type === user_types[1] && (
              <CustomTextField
                name="company_name"
                hintText={'Company Name'}
                label={'Company Name'}
                onChange={handleInputChange}
              />
            )}
            <p className="mt-4 text-xl font-times text-font">
              Where did you hear about us?
            </p>
            <CustomDropDown
              options={user_sources}
              value={'Twitter'}
              onSelect={(ev) => {
                setuserSource(ev.target.value);
              }}
            />
            {userSource === 'Other' && (
              <CustomTextField
                name="user_source"
                hintText={'Where did you hear about us?'}
                label={'Where did you hear about us?'}
                onChange={handleInputChange}
              />
            )}
          </div>
        )}
        <div className=" flex flex-col justify-center items-center w-auto font-times text-font">
          <CustomButton
            type={'submit'}
            label={isRegister ? 'Register' : 'Login'}
            onClick={handleSubmit}
          />
        </div>
      </div>
      <div className="flex py-2 justify-center font-times text-font">
        <a
          href={isRegister ? '/login' : '/register'}
          className="px-1 font-inter text-font font-bold"
        >
          {isRegister ? 'Login' : 'Register'}
        </a>
      </div>
    </form>
  );
};

export default AuthCard;
